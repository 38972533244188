import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

import yepmed from "../images/yepmed.png"
import crossdev from "../images/crossdev.png"
import restartmed from "../images/restartmed.png"

const CoopPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Synergies avec les projets IEV CTF MED</h2>
      <p>
        L&#39;un des principes fondamentaux sur lesquels repose le projet FISH MED NET est la
        création d&#39;un environnement coopératif entre les différents acteurs, offrant la nécessaire
        visibilité des acteurs impliqués auprès des tiers. Tous ces efforts visent à un monde meilleur
        dans lequel vivre et, de même, le projet recherche des synergies avec d&#39;autres projets IEV
        CBC MED qui partagent des objectifs similaires. Sur cette page, vous pouvez voir les
        différents projets qui ont des synergies avec FISH MED NET et en savoir plus sur les ODD
        des Nations Unies partagés avec chaque projet.
      </p>

      <div className="coop">
        <a href="https://www.enicbcmed.eu/projects/yep-med"><img src={yepmed}/></a>
        <p>
          Le projet Emploi des jeunes dans les ports de la Méditerranée vise à accroître l'implication des jeunes en améliorant le niveau de la logistique portuaire, à augmenter les opportunités d'emploi local avec des stages et à renforcer le rôle des PME opérant dans les écosystèmes portuaires. Le projet vise à créer un partenariat collaboratif national et transnational entre les acteurs impliqués dans la logistique portuaire à travers le bassin méditerranéen.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://www.hiddenmediterranean.net/en/"><img src={crossdev}/></a>
        <p>
          <b>CROSSDEV PROJECT</b> est un projet construit pour élever les destinations touristiques moins connues à travers la culture socio-économique locale et leur patrimoine à travers la Méditerranée. Le projet a développé une collaboration entre les pays en améliorant les politiques et pratiques de tourisme durable dans le but d'améliorer les moyens de subsistance des parties prenantes impliquées.
        </p>
      </div>
      <hr/>

      <div className="coop">
        <a href="https://medsustainabletourism.net/"><img src={restartmed}/></a>
        <p>
          Le projet <b>RESTART MED!</b> contribue au développement économique et social de la Méditerranée, en revitalisant l'économie touristique durable après la pandémie à travers 4 stratégies clés : diversification, innovation, désaisonnalisation et développement de l'économie locale.
          Le projet vise à remodeler la demande touristique, à aider les autorités à élaborer des politiques de tourisme durable et à encourager la coopération entre les acteurs du tourisme.
        </p>
      </div>

      <h2>Explorez parmi les dernières propositions</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default CoopPage
